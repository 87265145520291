import React, { Component } from "react";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto";

class CV extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var bio = "Biografía";
            var texto1 = `Soy Emilia Naistat, artista visual. Nací en el año 1997, en la capital de Buenos Aires
donde vivo y tengo mi taller. Mi formación comienza desde muy chica, cuando
mis xadres me llevaron a una serie de talleres de arte. El secundario lo hice en un
bachiller técnico donde me especialicé en diseño industrial. Posteriormente
estudié Artes Visuales en la UNA (Universidad Nacional de las Artes), donde
obtuve la Licenciatura con orientación en Escultura.
<br/> En el camino hice
escenografía teatral en la EMAD (Escuela Metropolitana de Arte Dramático),
clínica de obra con Juliana Iriart, asistí al taller de escultura de Edgardo Madanes y
participé de varios programas artísticos: CIA (Centro de Investigaciones
Antifascistas, 2019), programa a cargo de Mariela Scafati, Marie Bardet, Gerardo
Jorge, Juan Laxagueborde y Santiago Villanueva, PAC (Prácticas Artísticas
Contemporáneas, 2020) programa anual de encuentros de análisis, crítica y
producción de arte, a cargo de la galería Gachi Prieto Arte Contemporáneo
Latinoamericano, Taller Compartido (2021-2022) programa anual para el desarrollo
y la reflexión colectiva de las prácticas artísticas contemporáneas, a cargo de la
galería CRUDO Arte Contemporáneo, de la ciudad de Rosario, en la provincia de
Santa Fe.`;

            var texto2 = `  <br/>
                <br/>
En el 2020 participé de una muestra colectiva llamada Topografías del habitar, en
la galería Gachi Prieto, curada por Julieta Pestarino, Mariel Szlifman y Viviana
Zargon. En el 2021 presenté mi primera muestra individual llamada Recuerdo
Discoteca, en una antigua casa que se encontraba a días de ser demolida. En
febrero de este año 2022, participé de una residencia artística con estudio abierto
llamada PULSO HELADO, en la galería CRUDO, donde realicé un mural con
carbonilla.                
                <br/>
                <br/>
Actualmente me desempeño como ayudante de cátedra en la UNA, en la materia
Oficios y Técnicas de las Artes Visuales de Pintura, así como también soy parte del
equipo de selección de artistas y seguimiento de muestras, en el programa
federal Son tus Museos, impulsado por el Ministerio de Cultura de la Ciudad de
Buenos Aires.`;


            var estudios = "Estudios";
            var estidiostexto = `
                <span style='color:#33B82D; font-size:15px'>2016-2022 </span>
                <br/>
                Licenciatura en Artes Visuales con Orientación en Escultura. Universidad
                Nacional de las Artes (UNA), Buenos Aires
                <br/>
                <br/>
                <span style='color:#33B82D; font-size:15px'> 2018-actualidad </span>
                <br/>
                Actuación. Escuela de Actuación Nora Moseinco, Buenos Aires
                <br/><br/>
                Teatro Bravard, Buenos Aires
                <br/>
                <br/>
                <span style='color:#33B82D; font-size:15px'> 2015 </span>
                <br/>
                Escenografía. Escuela Metropolitana de Arte Dramático (EMAD), Buenos Aires
                <br/>
                <br>
                <span style='color:#33B82D; font-size:15px'> 2010-2014 </span>
                <br/>
                Bachiller Técnico con Orientación en Diseño Industrial. Escuela Técnica ORT, Buenos Aires
                <br/>
                
                 `;

            var courses = "Programas de formación";
            var coursestext = ` 
                <span style='color:#0B2CCC;font-size:15px; '> 2022 </span>
                <br/>
                PULSO HELADO, residencia y estudio abierto, en el marco del programa 
                Taller compartido, en la Galería CRUDO, Rosario, Santa Fé
                <br/><br/>
                Taller de pintura a cargo de Juan Reos
                <br/>
                <br/>
            
                <span style='color:#0B2CCC;font-size:15px;'> 2021 </span>
                
                <br/>
                Taller Compartido, Crudo Arte Contemporáneo, programa anual para el
                desarrollo y la reflexión colectiva de las prácticas artísticas contemporáneas 

                <br/>
                <br/>
                <span style='color:#0B2CCC;font-size:15px;'> 2020 </span>
                
                <br/>
                Prácticas Artísticas Contemporáneas, PAC, programa anual de encuentros de
                análisis, crítica y producción de arte, a cargo de la galería Gachi Prieto Arte
                Contemporáneo Latinoamericano

                <br/>
                <br/>
                <span style='color:#0B2CCC;font-size:15px;'> 2019 </span>
                
                <br/>
                Programa del Centro de Investigaciones Antifascistas, CIA a cargo de Mariela
                Scafati, Marie Bardet, Gerardo Jorge, Juan Laxagueborde y Santiago Villanueva

                <br/>
                <br/>

                <span style='color:#0B2CCC;font-size:15px;'> 2018 </span>
                
                <br/>
                Taller y Clínica a cargo de Juliana Iriart<br/><br/>
                Taller “Simultáneas II” a cargo de Eduardo Stupía y Tulio de Sagastizábal. Casa Matienzo
                <br/>
                <br/>

                <span style='color:#0B2CCC;font-size:15px;'> 2015-2017 </span>
                
                <br/>
                Taller de escultura a cargo de Edgardo Madanes

                <br/>
                <br/>

                <span style='color:#0B2CCC;font-size:15px;'>   2015 </span>
              
                <br/>
                Taller de Pintura al Óleo. University of Arts (UAL), Londres <br/><br/>
                Taller de Dibujo con Modelo Vivo. University of Arts (UAL), Londres
                <br/>                
                 `;
            var experiencias = "Experiencias laborales";
            var experienciastext = `
                <span style='color:#33B82D; font-size:15px'>2022 </span>
                <br/>
                Ayudante de cátedra en la Universidad Nacional de las Artes, en la materia Oficios
y Técnicas de las Artes Visuales de Pintura en los niveles I, II y III <br/><br/>
Miembro del programa federal Son tus Museos, impulsado por el Ministerio de
Cultura de la Ciudad de Buenos Aires, en el rol de selección de artistas y
seguimiento de sus muestras
                <br/>
                
                 `;
            var exposiciones = "Exposiciones";
            var exposicionestext = `
                <span style='color:#d01b0c;font-size:15px;'> 2022 </span>
                <br/>
                Las asesinas de la cueva, muestra colectiva en la Vecinal Barrial de Villa del Parque, Buenos Aires
                <br/><br/>
                PULSO HELADO, muestra colectiva en la Galería CRUDO, Rosario, Santa Fé

                <br/>
                <br/>

                <span style='color:#d01b0c;font-size:15px;'> 2021 </span>
                <br/>
                Recuerdo Discoteca, muestra individual, Casa Curitas, Buenos Aires
                <br/>
                <br/>

                <span style='color:#d01b0c;font-size:15px;'> 2020 </span>
                <br/>
                Topografías del habitar, muestra colectiva en la galería Gachi Prieto Arte
                Contemporáneo, curada por Julieta Pestarino, Mariel Szlifman y Viviana
                Zargón, Buenos Aires
                <br/>
                <br/>

                <span style='color:#d01b0c;font-size:15px;'> 2019 </span>
                <br/>
                Las asesinas de la Escalera, muestra colectiva en casa Garay, Buenos Aires<br/><br/>
                Asesines del Espacio en Avellaneda, muestra colectiva, curada por Carola Zech
                y Fernanda Laguna, en la Escuela de Música Popular de Avellaneda, Buenos
                Aires 
                <br/>
                <br/>
            
                <span style='color:#d01b0c;font-size:15px;'> 2018 </span>
                <br/>
                Salón Nacional Prilidiano Pueyrredón, Buenos Aires<br/><br/>
                Salón Malón Octubre, Buenos Aires
                <br/>
                <br/>
               `;

        } else {
            var bio = "Bio";
            var texto1 = `I am Emilia Naistat, visual artist. I was born in 1997 in Buenos Aires, capital city,
where I live and have my own studio. I have studied since I was very young, when
my parents took me to several art classes. I took my secondary school studies at a
technical school where I specialized in industrial design. After that, I studied Visual
Arts at UNA (Universidad Nacional de las Artes –National University of the Arts-),
where I got my undergraduate degree in Sculpture. At the same time, I studied
theatre scenography at EMAD (Escuela Metropolitana de Arte Dramático
–Metropolitan School of Dramatic Arts-), I took art work mentoring classes with Juliana Iriart, I
attended Edgardo Madanes’ sculpture workshop, and I was part of several art
programmes: CIA (Centro de Investigaciones Antifascistas –Anti-fascism Research
Centre-, 2019), programme headed by Mariela Scafati, Marie Bardet, Gerardo
Jorge, Juan Laxagueborde, and Santiago Villanueva, PAC (Prácticas Artísticas
Contemporáneas –Practice of Contemporary Arts-, 2020) yearly programme to
analyse, review, and produce art, headed by Gachi Prieto Latin-American
Contemporary Art gallery, Shared Workshop (2021-2022) yearly programme to
develop and collectively reflect on contemporary artistic practices, headed by
CRUDO Contemporary Art, in the city of Rosario, Province of Santa Fe.`;

            var texto2 = `  <br/>
                <br/>
In 2020, I took part of a collective exhibition called Topografías del Habitar –
Topographies of Living-, at Gachi Prieto gallery, curated by Julieta Pestarino, Mariel
Szlifman, Viviana Zargon. In 2021, I held my first solo exhibition called Recuerdo
Discoteca –Disco Memory-, in an old house that was just days away from being
demolished. In February 2022, I was part of an artistic residency, with open studio,
called PULSO HELADO –FROZEN BEAT-, at CRUDO gallery, where I painted a wall
with charcoal.                
                <br/>
                <br/>
Nowadays, I work as teaching assistant at UNA, at the Painting Visual Arts Job and
Techniques subject. I am also part of the artist selection and exhibitions follow up
team from Son tus Museos –Your Museums- federal programme, fostered by the
City of Buenos Aires’ Department for Culture.`;

            var estudios = "Education";
            var estidiostexto = `
                <span style='color:#33B82D; font-size:15px'>2016-2022</span>
                <br/>
                Undergraduate degree in Visual Arts with specialty in Sculpture. National
University of the Arts (UNA), City of Buenos Aires
                <br/>
                <br/>
                <span style='color:#33B82D; font-size:15px'>2018-to date </span>
                <br/>
                Drama, Nora Moseinco Drama School, Buenos Aires
                <br/><br/>
                Drama, Bravard Theatre, Buenos Aires
                <br/>
                <br/>
                <span style='color:#33B82D; font-size:15px'> 2015 </span>
                <br/>
                Scenography. Metropolitan School of Dramatic Arts (EMAD), City of Buenos Aires
                <br/>
                <br>
                <span style='color:#33B82D; font-size:15px'> 2010-2014 </span>
                <br/>
                Technical Secondary School Diploma with specialty in Industrial Design. ORT <br/>
Technical School, Buenos Aires
                <br/>
                
                 `;

            var courses = "Artistic Development Programmes";
            var coursestext = ` 
                <span style='color:#0B2CCC;font-size:15px; '> 2022 </span>
                <br/>
                PULSO HELADO, residency and open study, as part of the Shared Workshop
programme at CRUDO Gallery, Rosario, Santa Fé
                <br/><br/>
                Painting workshop headed by Juan Reos
                <br/>
                <br/>
            
                <span style='color:#0B2CCC;font-size:15px;'> 2021 </span>
                
                <br/>
                Shared Workshop, Crudo Contemporary Art, yearly programme to develop and
collectively reflect on contemporary artistic practices 

                <br/>
                <br/>
                <span style='color:#0B2CCC;font-size:15px;'> 2020 </span>
                
                <br/>
                Practice of Contemporary Arts, PAC, yearly programme to analyse, review, and
produce art, headed by Gachi Prieto Latin-American Contemporary Art gallery

                <br/>
                <br/>
                <span style='color:#0B2CCC;font-size:15px;'> 2019 </span>
                
                <br/>
                CIA Anti-Fascism Research Centre Programme headed by Mariela Scafati, Marie
Bardet, Gerardo Jorge, Juan Laxagueborde, and Santiago Villanueva

                <br/>
                <br/>

                <span style='color:#0B2CCC;font-size:15px;'> 2018 </span>
                
                <br/>
                Workshop and art work mentoring headed by Juliana Iriart <br/><br/>
“Simultaneous II” Workshop headed by Eduardo Stupía and Tulio de Sagastizábal.
Casa Matienzo
                <br/>
                <br/>

                <span style='color:#0B2CCC;font-size:15px;'> 2015-2017 </span>
                
                <br/>
                Sculpture Workshop headed by Edgardo Madanes

                <br/>
                <br/>

                <span style='color:#0B2CCC;font-size:15px;'>   2015 </span>
              
                <br/>
                Oil Painting Workshop. University of Arts (UAL), London <br/><br/>
                Live Model Drawing Workshop. University of Arts (UAL), London
                <br/>                
                 `;
            var experiencias = "Work Experience";
            var experienciastext = `
                <span style='color:#33B82D; font-size:15px'>2022 </span>
                <br/>
                Teaching Assistant at National University of the Arts, Painting Visual Arts Job and
Techniques subject, levels I, II y III <br/><br/>
Member of Your Museums federal Programme fostered by the City of Buenos
Aires’ Department for Culture, in the role of artist selection and exhibitions follow
up
                <br/>`;

            var exposiciones = "Exhibitions";
            var exposicionestext = `
                <span style='color:#d01b0c;font-size:15px;'> 2022 </span>
                <br/>
                Cave Murderers, collective exhibition at Villa del Parque Neighborhood, Buenos Aires
                <br/><br/>
                FROZEN BEAT, collective exhibition at CRUDO Gallery, Rosario, Santa Fé

                <br/>
                <br/>

                <span style='color:#d01b0c;font-size:15px;'> 2021 </span>
                <br/>
                Memory disco, solo exhibition, Casa Curitas, Buenos Aires
                <br/>
                <br/>

                <span style='color:#d01b0c;font-size:15px;'> 2020 </span>
                <br/>
                Topographies of Living, collective exhibition at Contemporary Art Gachi Prieto
gallery, curated by Julieta Pestarino, Mariel Szlifman, and Viviana Zargón, Buenos
Aires
                <br/>
                <br/>

                <span style='color:#d01b0c;font-size:15px;'> 2019 </span>
                <br/>
                Staircase Murderers, collective exhibition at Casa Garay, Buenos Aires<br/><br/>
                Space Murderers in Avellaneda, collective exhibition curated by Carola Zech and
Fernanda Laguna, at Avellaneda’s School of Popular Music, Buenos Aires
                <br/>
                <br/>
            
                <span style='color:#d01b0c;font-size:15px;'> 2018 </span>
                <br/>
                Prilidiano Pueyrredón National Hall, Buenos Aires<br/><br/>
                Malón Octubre Hall, Buenos Aires
                <br/>
                <br/>
               `;
        }
        return (
                <main className=" curRojo widthPer Escultura">
                    <EstructuraTexto
                        CvCss={""}
                        hideFlecha={true}
                        ImgTexto1={null}
                        margen={"margen"}
                        movCV={"movCV textobajo1"}
                        TextoBG={true}
                        Titulo={null}
                        Titulo2={"[Buenos Aires, 1997]"}
                        Rojo={null}
                        Rojo2={null}
                        cvTexto={true}
                        Verde={null}
                        Azul={null}
                        expanVal={true}
                        SubTitulo={"→ " + bio}
                        texto={texto1}
                        texto2={texto2}
                        color="secTxt"
                        color1="colorNegro"
                        ImgTexto={null}
                        primero="true"
                        />
                
                    <EstructuraTexto
                        ImgTexto1={null}
                        margen={"margen"}
                        movCV={"movCV textobajo1"}
                        TextoBG={true}
                        Titulo={null}
                        Titulo2={null}
                        Rojo={null}
                        Rojo2={null}
                        Verde={null}
                        Azul={null}
                        expanVal={true}
                        SubTitulo={"→ " + estudios}
                        texto={estidiostexto}
                        texto2={null}
                        color="terTxtFirst"
                        color1="colorNegro"
                        ImgTexto={null}
                        />
                
                    <EstructuraTexto
                        ImgTexto1={null}
                        margen={"margen"}
                        movCV={"movCV textobajo1"}
                        TextoBG={true}
                        Titulo={null}
                        Titulo2={null}
                        Rojo={null}
                        Rojo2={null}
                        Verde={null}
                        Azul={null}
                        expanVal={true}
                        SubTitulo={"→ " + courses}
                        texto={coursestext}
                        texto2={null}
                        color="cuart-txt1 "
                        color1="colorNegro"
                        ImgTexto={null}
                        />
                
                    <EstructuraTexto
                        ImgTexto1={null}
                        margen={"margen"}
                        movCV={"movCV textobajo1"}
                        TextoBG={true}
                        Titulo={null}
                        Titulo2={null}
                        Rojo={null}
                        Rojo2={null}
                        Verde={null}
                        Azul={null}
                        expanVal={true}
                        SubTitulo={"→ " + experiencias}
                        texto={experienciastext}
                        texto2={null}
                        color="terTxtFirst"
                        color1="colorNegro"
                        ImgTexto={null}
                        />
                
                    <EstructuraTexto
                        ImgTexto1={null}
                        margen={"margen"}
                        movCV={"movCV textobajo1"}
                        TextoBG={true}
                        Titulo={null}
                        Titulo2={null}
                        Rojo={null}
                        Rojo2={null}
                        Verde={null}
                        Azul={null}
                        expanVal={true}
                        SubTitulo={"→ " + exposiciones}
                        texto={exposicionestext}
                        texto2={null}
                        color="secTxt"
                        color1="colorNegro"
                        ImgTexto={null}
                        />
                </main>
                );
    }
}

export default CV;

import React, { Component, createRef } from "react";
import EstructuraImg from "../EstructuraImg/estructuraImg";
import "./pintura.css";

class Pintura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lsPint: [],
    };
    this.myRef = createRef();

  }

  async componentDidMount() {
    if (localStorage.getItem('Lenguaje') == 'espanish') {
            var language = "https://mimi.zimbadigital.net/backend/getPinturas.php";
        } else {
            var language = "https://mimi.zimbadigital.net/backend/getPinturas.php?lang=en";
        }
    let url = language; 
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        this.setState({ lsPint: res });
      });
  }

  render() {
    return (
      <main ref={this.myRef} className="curVerde Escultura m10top">
        {this.state.lsPint &&
          this.state.lsPint.map((item,i) => {
            var lsSubImg = item.galeria[0].SubGaleria;
            var lsHastag = item.Hash;
            let ocu = false;
            let estilos=null;
            let limite = null;
            let estilosTex = null;
            var brAct;
            var widthSub;

            if (i==4) {
              ocu=true;
              limite=3;
              widthSub='subImg';
            }
            if (i==5) {
              ocu=true;
              limite=6;
            }

            if (i==1) {
              estilosTex = 'lbSty';
              brAct=true;

            }

            if (this.state.lsPint.length-1==i){
            return (
              <EstructuraImg
                refe={this.myRef}
                Titulo={item.Titulo}
                Rojo={item.Descripcion}
                Rojo2={item.Medida}
                showMore={ocu}
                hideFotos={true}
                Lim = {limite}
                brAct={brAct}
                widthSub={widthSub}
                EstilosTex={estilosTex}
                Verde={"conflicto "}
                Azul={"fantasia"}
                WidthImg={item.galeria[0].WidthImg}
                ImgPrincipal={item.galeria[0].ImgPrincipal}
                listGale={lsSubImg}
                hastLs={lsHastag}
                puntoFinal={true}
                elementOrigin={'pintura'}
              />
            );
          }else{
            return (
              <EstructuraImg
              refe={this.myRef}
              Titulo={item.Titulo}
              Rojo={item.Descripcion}
              Rojo2={item.Medida}
              showMore={ocu}
              hideFotos={true}
              Lim = {limite}
              brAct={brAct}
              widthSub={widthSub}
              EstilosTex={estilosTex}
              Verde={"conflicto "}
              Azul={"fantasia"}
              WidthImg={item.galeria[0].WidthImg}
              ImgPrincipal={item.galeria[0].ImgPrincipal}
              listGale={lsSubImg}
              hastLs={lsHastag}
              elementOrigin={'pintura'}
              />
            );
          }
          })}
      </main>
    );
  }
}

export default Pintura;

import React, { Component, createRef } from "react";
import EstructuraVideo from "./EstructuraVideo/estructuraVideo";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = { lsVid: [] };
    this.myRef = createRef();
  }

  componentDidMount() {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var language = "https://mimi.zimbadigital.net/backend/getVideos.php";
        } else {
            var language = "https://mimi.zimbadigital.net/backend/getVideos.php?lang=en";
        }
        let url = language;
    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        this.setState({ lsVid: resp });
      });
  }

  render() {

    return (
      <main ref={this.myRef} className=" p-0 m10top  curVerde Escultura">
          {this.state.lsVid && this.state.lsVid.map((item,i) => {
            var val;
            var ultimo = 'no';
              if (i==0) {
                  val='pt-2'
              }else{
                  val=''
              }
              if (i==(this.state.lsVid.length - 1)) {
                  ultimo='si'
              }
            return(
                    <EstructuraVideo
                    csN={val}
                    refe={this.myRef}
                    editVideo={"editVideo"}
                    Titulo={item.Titulo}
                    Verde={item.Verde}
                    Verde2={item.Verde2}
                    Rojo={item.Rojo}
                    Rojo2={item.Rojo2}
                    Azul={item.Azul}
                    Azul1={item.Azul1}
                    Azul2={item.Azul2}
                    urlVideo={item.urlVideo}
                    urlImg={item.urlImg}
                    ultimo={ultimo}
                  />
                )  
          }) }


      </main>
    );
  }
}
export default Videos;
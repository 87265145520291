import React, { Component, createRef } from "react";
import EstructuraImg from "../EstructuraImg/estructuraImg";
import "./esculturas.css";

class Escultura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsEst: [],
        };
        this.myRef = createRef();
    }

    async componentDidMount() {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var language = "https://mimi.zimbadigital.net/backend/getEsculturas.php?lang=es";
        } else {
            var language = "https://mimi.zimbadigital.net/backend/getEsculturas.php?lang=en";
        }

        let url = language;
        fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    this.setState({lsEst: res});
                });
    }

    render() {
        return (
                <main ref={this.myRef} className="m-0 p-0 d-flex  justify-content-end Escultura">
                    <div  className='m10top'>
                        {this.state.lsEst.map((item, i) => {
                                        var lsSubImg = item.galeria[0].SubGaleria;
                                        var lsHastag = item.Hash;


                                        if (this.state.lsEst.length - 1 == i) {
                                            return (
                                                    <EstructuraImg
                                                        refe={this.myRef}
                                                        disImg={'justify-content-sm-between '}
                                                        Titulo={item.Titulo}
                                                        Rojo={item.Descripcion}
                                                        Rojo2={item.Medida}
                                                        Verde={"conflicto "}
                                                        Azul={"fantasia"}
                                                        WidthImg={item.galeria[0].WidthImg}
                                                        ImgPrincipal={item.galeria[0].ImgPrincipal}
                                                        listGale={lsSubImg}
                                                        hastLs={lsHastag}
                                                        puntoFinal={true}
                                                        elementOrigin={'esculturas'}
                                                        />
                                                        );

                                            } else {
                                                return (
                                                    <EstructuraImg
                                                        refe={this.myRef}
                                                        disImg={'justify-content-sm-between '}
                                                        Titulo={item.Titulo}
                                                        Rojo={item.Descripcion}
                                                        Rojo2={item.Medida}
                                                        Verde={"conflicto "}
                                                        Azul={"fantasia"}
                                                        WidthImg={item.galeria[0].WidthImg}
                                                        ImgPrincipal={item.galeria[0].ImgPrincipal}
                                                        listGale={lsSubImg}
                                                        hastLs={lsHastag}
                                                        elementOrigin={'esculturas'}
                                                        />
                                                        );
                                            }


                                    })}
                    </div>
                </main>
                );
    }
}

export default Escultura;

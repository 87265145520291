import React, { Component } from "react";
import EstructuraImg from "../EstructuraImg/estructuraImg";
import EstructuraTexto from "../EstructuraTexto/estructuraTexto_1";
import "./texto.css";

class Texto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsTextos: [],
        };
    }

    componentDidMount() {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var language = "https://mimi.zimbadigital.net/backend/getTextos1.php";
        } else {
            var language = "https://mimi.zimbadigital.net/backend/getTextos1.php?lang=en";
        }
        let url = language;
        fetch(url)
                .then((resp) => resp.json())
                .then((resp) => {
                    this.setState({
                        lsTextos: resp,
                    });
                });
    }

    render() {
        return (
                <main className="wt10 Escultura">
                
                    {this.state.lsTextos && this.state.lsTextos.map((item, i) => {
                                    var imgUrl;
                                    var imgUrl2 = undefined;
                                    try {
                                        imgUrl = require(`${item.imgUrl}`);
                                    } catch (error) {
                                    }

                                    if (i === 2) {
                                        imgUrl2 = imgUrl;
                                        imgUrl = null;
                                    }
                                    return(
                                                <EstructuraTexto
                                                    flechaColor={item.flechaColor}
                                                    AlingText={"AlingText"}
                                                    Titulo={item.Titulo}
                                                    Titulo2={null}
                                                    Rojo={item.subTitulo}
                                                    Rojo2={item.Hash1}
                                                    Verde={item.Hash2}
                                                    Azul={item.Hash3}
                                                    SubTitulo={null}
                                                    mtH={"mtH"}
                                                    texto={
                                                            item.Resumen
                                                    }
                                                    texto2={item.Texto}
                                                    color={item.colorTxt}
                                                    color1={item.colorTxt}
                                                    ImgTexto={imgUrl}
                                                    ImgTexto1={imgUrl2}
                                                    />
                                                );


                                })}
                
                </main>
                );
    }
}

export default Texto;
import React, { Component } from "react";
import { Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Buscador from "../Buscador/buscador";
import "./menu.css";
        class Menu extends Component {
        componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
        this.onRouteChanged();
        }
        }
        constructor(props) {
        super(props);
                this.state = {
                activo: "activo",
                        activo2: "activo2",
                        activo3: "activo3",
                        transparentBg: "",
                        navExpanded: false,
                        estBg: ''
                };
        }
        setEmptyBgForHeader() {
                if (this.props.location.pathname == '/Statement') {
        this.setState({ transparentBg: "vacioBg" });
                this.setState({
                estBg: ''
                });
        } else {
        this.setState({ transparentBg: "" });
        }
        }
        onRouteChanged() {
        this.setEmptyBgForHeader()
        }
        componentDidMount() {
        this.setEmptyBgForHeader();
        }
        setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
        }
        closeNav = () => {
        this.setState({ navExpanded: false });
        }        
        changeToWhiteMenu = () => {
        var nav = document.getElementById('basic-navbar-nav').classList;
        if(nav.value === 'navbar-collapse collapse'){
            document.getElementById('columna2').classList.add('d-none');
        }else{
            document.getElementById('columna2').classList.remove('d-none');
        }
        if (this.state.estBg == '') {            
        this.setState({
        estBg: 'bg-white'
        });
        } else { 
            this.setState({
        estBg: ''
        });
        }
        }
        render(props) {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
        var fuente1 = "Fuente4";
                var fuente2 = "Fuente5";
                var escul = "Esculturas";
                var dibujo = "Dibujos";
                var pintura = "Pinturas";
                var grabado = "Grabados";
                var instala = "Instalaciones";
                var video = "Videos";
                var foto = "Fotografias";
                var textos = "Textos";
                var archivo = "Archivo";
                var stament = "Statement";
                var cv = "CV";
                var contact = "Contacto";
                var stamentSub = "[Todoloquepiensoyloqueno]";
                var cvSub = "Buenos Aires, 1997";
                var contactSub = "Hola :D";
        } else {
        var fuente1 = "Fuente5";
                var fuente2 = "Fuente4";
                var escul = "Sculptures";
                var dibujo = "Drawings";
                var pintura = "Paintings";
                var grabado = "Engravings";
                var instala = "Installations";
                var video = "Videos";
                var foto = "Photographs";
                var textos = "Texts";
                var archivo = "Archive";
                var stament = "Statement";
                var cv = "CV";
                var contact = "Contact";
                var stamentSub = "[EverythingIthinkandnot]";
                var cvSub = "Buenos Aires, 1997";
                var contactSub = "Hi! :D";
        }
        var subtitle = "";
        var puntoN = require("../ImgEstilos/puntosAzul.png");
                var puntoB = require("../ImgEstilos/puntosBlanco.png");
                var str = this.props.location.pathname;
                str = str.replace("/", "");
                var optionalStr;
                var estilo = "";
                try {
                if (str == "Todoloquepiensoyloqueno") {
                str = "";
                        estilo = "w-100  h-100 position-absolute estP  ";
                        this.props.chStyleLoqPienso("todoloqStyle");
                } else {
                this.props.chStyleLoqPienso("");
                }
                } catch (error) { }
        try {
        if (this.props.location.pathname == "/") {
        this.props.chgCursor('actAzul');
                estilo = "Fuente";
        } else if (this.props.location.pathname == "/"+escul) {
                this.props.chgCursor('actRojo');
                estilo = "Fuente";
        } else if (this.props.location.pathname == "/"+dibujo) {
        this.props.chgCursor('actNegro');
                estilo = "Fuente1";
        } else if (this.props.location.pathname == "/"+pintura) {
        this.props.chgCursor('actVerde');
                estilo = "Fuente";
        } else if (this.props.location.pathname == "/"+grabado) {
        this.props.chgCursor('actRojo');
                estilo = "Fuente1";
        } else if (this.props.location.pathname == "/"+instala) {
        this.props.chgCursor('actNegro');
                estilo = "Fuente";
        } else if (this.props.location.pathname == "/"+video) {
        this.props.chgCursor('actVerde');
                estilo = "Fuente1";
        } else if (this.props.location.pathname == "/"+foto) {
        this.props.chgCursor('actRojo');
                estilo = "Fuente";
        } else if (this.props.location.pathname == "/"+textos) {
        this.props.chgCursor('actNegro');
                estilo = "Fuente1";
        } else if (this.props.location.pathname == "/"+archivo) {
        this.props.chgCursor('actNegro');
                estilo = "Fuente1";
        } else if (this.props.location.pathname == "/"+stament) {
        this.props.chgCursor('actVerde');
                estilo = "Fuente2";
                subtitle = stamentSub;
        } else if (this.props.location.pathname == "/CV") {
        this.props.chgCursor('actRojo');
                estilo = "Fuente";
                subtitle = "["+cvSub+"]";
        } else if (this.props.location.pathname == "/"+contact) {
        this.props.chgCursor('actNegro');
                estilo = "Fuente1";
                subtitle = "["+contactSub+"]";
        }
        }
        catch (error) {
        }
        if(document.getElementById('navbar-collapse collapse')){ 
            var nav = document.getElementById('basic-navbar-nav').classList;
            if(nav.value === 'navbar-collapse collapse'){
                document.getElementById('columna2').classList.add('d-none');
            }else{
                document.getElementById('columna2').classList.remove('d-none');
            }
        }
        return (
                <>
<div className={"submenu " + this.state.transparentBg}>
    <label className={"textSubmenu  submenuFont " + estilo + " " + this.state.transparentBg}>
        {str} <spam class='d-inline d-md-none'>{subtitle}</spam>
    </label>
</div>
{this.props.location.pathname !== '/' && <div className={"submenu2 " + this.state.transparentBg}>
    <div className={"w-100 punto2 " + this.state.transparentBg}></div>
</div>
}
<Navbar
    id="navbartop"
    expand="lg"
    onToggle={this.setNavExpanded}
    expanded={this.state.navExpanded}
    className={"menu d-block " + this.state.transparentBg + ' ' + this.state.estBg}
    >
    <Navbar.Brand className="Nombre  respNombre1">
        <Link
            to="/"
            className={
                "Nombre sub " +
                (this.props.location.pathname == "/"
                        ? this.state.activo0
                        : "vacio")
            }
            >
        {" "}
        EMILIA NAISTAT
        </Link>
    </Navbar.Brand>
    <Navbar.Toggle
        aria-controls="basic-navbar-nav "
        className={"menuRes  ml-auto respNombre1 " + this.state.transparentBg}
        onClick={this.changeToWhiteMenu}
        />

    <div className="w-100 punto2 pnt "></div>

    <Navbar.Collapse id="basic-navbar-nav">
        <div className="coverblack"></div>
        <Nav className="d-block  respNombre mt-0" >
            <Nav.Link className="curRojo p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+escul}
                    className={
                "Fuente  " +
                (this.props.location.pathname == "/"+escul
                        ? this.state.activo
                        : "vacio")
                    }
                    >
                {escul}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curNegro p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+dibujo}
                    className={
                "Fuente1  " +
                (this.props.location.pathname == "/"+dibujo
                        ? this.state.activo2
                        : "vacio")
                    }
                    >
                {dibujo}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curVerde p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+pintura}
                    className={
                "Fuente2 " +
                (this.props.location.pathname == "/"+pintura
                        ? this.state.activo3
                        : "vacio")
                    }
                    >
                {pintura}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curRojo p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+grabado}
                    className={
                "Fuente " +
                (this.props.location.pathname == "/"+grabado
                        ? this.state.activo
                        : "vacio")
                    }
                    >
                {grabado}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curNegro p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+instala}
                    className={
                "Fuente1 " +
                (this.props.location.pathname == "/"+instala
                        ? this.state.activo2
                        : "vacio")
                    }
                    >
                {instala}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curVerde p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+video}
                    className={
                "Fuente2 " +
                (this.props.location.pathname == "/"+video
                        ? this.state.activo3
                        : "vacio")
                    }
                    >
                {video}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curRojo p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+foto}
                    className={
                "Fuente " +
                (this.props.location.pathname == "/"+foto
                        ? this.state.activo
                        : "vacio")
                    }
                    >
                {foto}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curNegro p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+textos}
                    className={
                "Fuente1 " +
                (this.props.location.pathname == "/"+textos
                        ? this.state.activo2
                        : "vacio")
                    }
                    >
                {textos}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="Buscador " onClick={this.closeNav}>
                <Buscador
                    changeThemeChild={this.props.changeThemeChild}
                    changeThemeWhiteChild={this.props.changeThemeWhiteChild}
                    tema={this.props.tema}
                    botBla={puntoB}
                    botNeg={puntoN}
                    />
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curNegro p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+archivo}
                    className={
                "Fuente1 " +
                (this.props.location.pathname == "/"+archivo
                        ? this.state.activo2
                        : "vacio")
                    }
                    >
                {archivo}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curVerde p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+stament}
                    className={
                "Fuente2 " +
                (this.props.location.pathname == "/"+stament
                        ? this.state.activo3
                        : "vacio")
                    }
                    >
                {stament}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className=" curRojo p-0 " onClick={this.closeNav}>
                <Link
                    to="/CV"
                    className={
                "Fuente " +
                (this.props.location.pathname == "/CV"
                        ? this.state.activo
                        : "vacio")
                    }
                    >
                CV
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <Nav.Link className="curNegro p-0 " onClick={this.closeNav}>
                <Link
                    to={"/"+contact}
                    className={
                "Fuente1 mb-5 " +
                (this.props.location.pathname == "/"+contact
                        ? this.state.activo2
                        : "vacio")
                    }
                    >
                {contact}
                </Link>
            </Nav.Link>
            <div className="w-100 punto2 "></div>
            <div className='respoNivl'>
                <Nav.Link className="Fuente3 mt-3 movD d-flex">
                    <Link className={fuente1} onClick={this.props.changeLanguageChild}>ES</Link>{" "}
                    <div className="p-1 text-dark icono">*</div>{" "}
                    <Link className={fuente2} onClick={this.props.changeLanguageESChild}>EN</Link>
                </Nav.Link>
                {(this.props.tema == "bg-white" || this.props.tema == null) && (
                <Nav.Link
                    className=" p-0  iconCh movZ "
                    onClick={this.props.changeThemeChild}
                    >
                    <Image className="puntoN Fuente3 " src={puntoN.default} />
                </Nav.Link>
                )}
                {this.props.tema == "bg-black"  && (
                <Nav.Link
                    className=" p-0  iconCh color-white movZ"
                    onClick={this.props.changeThemeWhiteChild}
                    >
                    <Image className="puntoN Fuente3" src={puntoB.default} />
                </Nav.Link>
                )}
            </div>
        </Nav>
    </Navbar.Collapse>
</Navbar>
</>
);
}

}

export default withRouter(Menu);
import React, { Component } from "react";
import './fotografias.css';

class Fotografias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnaOne: ''
        };
    }
    render() {
        var col1cantidadImg = Array();
        var col2cantidadImg = Array();
        var col3cantidadImg = Array();
        var col4cantidadImg = Array();
        var totalImg1 = 47;
        var totalImg2 = 49;
        var totalImg3 = 49;
        var totalImg4 = 53;

        for (let i = 1; i <= totalImg1; i++) {
            var auxImg;
            try {
                auxImg = require("./fotos/c1-" + i + ".jpg");
            } catch (error) {
                auxImg = require("./fotos/c1-" + i + ".JPG");
            }
            col1cantidadImg[i] = auxImg;
        }
        for (let j = 1; j <= totalImg2; j++) {
            var auxImg;
            try {
                auxImg = require("./fotos/c2-" + j + ".jpg");
            } catch (error) {
                auxImg = require("./fotos/c2-" + j + ".JPG");
            }
            col2cantidadImg[j] = auxImg;
        }
        for (let k = 1; k <= totalImg3; k++) {
            var auxImg;
            try {
                auxImg = require("./fotos/c3-" + k + ".jpg");
            } catch (error) {
                auxImg = require("./fotos/c3-" + k + ".JPG");
            }
            col3cantidadImg[k] = auxImg;
        }
        for (let l = 1; l <= totalImg4; l++) {
            var auxImg;
            try {
                auxImg = require("./fotos/c4-" + l + ".jpg");
            } catch (error) {
                auxImg = require("./fotos/c4-" + l + ".JPG");
            }
            col4cantidadImg[l] = auxImg;
        }
        if(document.getElementById('columna2')){
            document.getElementById('columna2').classList.remove('d-none');
        }
        return (
                <main className="bg-Foto h-auto position-relative curRojo ">
                    <div className="row  rowcol-2 m-0 p-0">
                        <div className="col-6-pr col-md-3 pr-0">
                            { col1cantidadImg.map((item, i) => {
                                            if (i <= 48) {
                                                return(
                                                    <div className='card m-0 p-0 mt-3 border-0 ' >
                                                        <img src={item.default} className='card-img-top border-0 ' alt=""/>
                                                    </div>

                                                        )

                                            }

                                        })}
                        </div>
                
                        <div className="col-6-pr col-md-3 pr-0">
                            { col2cantidadImg.map((item, j) => {
                                    if (j < 50) {
                                        return(
                                            <div className='card m-0 p-0 mt-3 border-0 ' >
                                                <img src={item.default} className='card-img-top border-0 ' alt=""/>
                                            </div>

                                                )

                                    }

                                })}
                        </div>
                
                        <div className="col-6-pr col-md-3 pr-0">
                            { col3cantidadImg.map((item, k) => {
                                    if (k < 50) {
                                        return(
                                            <div className='card m-0 p-0 mt-3 border-0 ' >
                                                <img src={item.default} className='card-img-top border-0 ' alt=""/>
                                            </div>
                                                )
                                    }
                                })}
                        </div>
                        <div className="col-6-pr col-md-3 p-0">
                            { col4cantidadImg.map((item, l) => {
                                    if (l < 54) {
                                        return(
                                            <div className='card m-0 p-0 mt-3 border-0 ' >
                                                <img src={item.default} className='card-img-top border-0 ' alt=""/>
                                            </div>

                                                )

                                    }

                                })}
                        </div>
                    </div>
                </main>
                );
    }
}

export default Fotografias;

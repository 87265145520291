import React, { Component }
from 'react';
import { Col, Row }
from 'react-bootstrap';
import './archivosEstil.css'
import EstructuraArchivo from './EstructuraArchivo/estructuraArchivo';

class Archivos extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        var imgArch = Array();
        for (let i = 0; i <= 495; i++) {
            imgArch.push(require("./archivos/" + i + ".jpg"));
        }
        const imgArch1 = require('./archivos/18.jpg');
        const imgArch2 = require('./archivos/b.jpg');
        const imgArch3 = require('./archivos/3.jpg');
        const imgArchA = require('./archivos/5.jpg');
        const imgArchB = require('./archivos/6.jpg');
        const imgArchC = require('./archivos/7.jpg');
        const imgArch4 = require('./archivos/4.jpg');
        const imgArch8 = require('./archivos/8.jpg');
        const imgArch9 = require('./archivos/9.jpg');
        const imgArch10 = require('./archivos/10.jpg');
        const imgArch11 = require('./archivos/11.jpg');
        const imgArch12 = require('./archivos/12.jpg');
        const imgArch13 = require('./archivos/13.jpg');
        const imgArch14 = require('./archivos/14.jpg');
        const imgArch15 = require('./archivos/15.jpg');
        const imgArch16 = require('./archivos/16.jpg');
        const imgArch17 = require('./archivos/17.jpg');
        const imgArch18 = require('./archivos/18.jpg');
        const imgArch19 = require('./archivos/19.jpg');
        const imgArch20 = require('./archivos/20.jpg');
        const imgArch21 = require('./archivos/21.jpg');
        const imgArch22 = require('./archivos/22.jpg');
        const imgArch23 = require('./archivos/23.jpg');
        const imgArch24 = require('./archivos/24.jpg');
        const imgArch25 = require('./archivos/25.jpg');
        const imgArch26 = require('./archivos/26.jpg');
        const imgArch27 = require('./archivos/27.jpg');
        const imgArch28 = require('./archivos/28.jpg');
        const imgArch29 = require('./archivos/29.jpg');
        const imgArch30 = require('./archivos/30.jpg');
        const imgArch31 = require('./archivos/31.jpg');
        const imgArch32 = require('./archivos/32.jpg');
        const imgArch33 = require('./archivos/33.jpg');
        const imgArch34 = require('./archivos/34.jpg');
        const imgArch35 = require('./archivos/35.jpg');
        const imgArch36 = require('./archivos/36.jpg');
        const imgArch37 = require('./archivos/37.jpg');
        const imgArch38 = require('./archivos/38.jpg');
        const imgArch39 = require('./archivos/39.jpg');
        const imgArch40 = require('./archivos/40.jpg');
        const imgArch41 = require('./archivos/41.jpg');
        const imgArch42 = require('./archivos/42.jpg');
        const imgArch43 = require('./archivos/43.jpg');
        const imgArch44 = require('./archivos/44.jpg');
        const imgArch45 = require('./archivos/45.jpg');
        const imgArch46 = require('./archivos/46.jpg');
        const imgArch47 = require('./archivos/47.jpg');
        const imgArch48 = require('./archivos/48.jpg');
        const imgArch49 = require('./archivos/49.jpg');
        const imgArch50 = require('./archivos/50.jpg');
        const imgArch51 = require('./archivos/51.jpg');
        const imgArch52 = require('./archivos/52.jpg');
        const imgArch53 = require('./archivos/53.jpg');
        const imgArch54 = require('./archivos/54.jpg');
        const imgArch55 = require('./archivos/55.jpg');
        const imgArch56 = require('./archivos/56.jpg');
        const imgArch57 = require('./archivos/57.jpg');
        const imgArch58 = require('./archivos/58.jpg');
        const imgArch59 = require('./archivos/59.jpg');
        const imgArch60 = require('./archivos/60.jpg');
        const imgArch61 = require('./archivos/61.jpg');
        const imgArch62 = require('./archivos/62.jpg');
        const imgArch63 = require('./archivos/63.jpg');
        const imgArch64 = require('./archivos/64.jpg');
        const imgArch65 = require('./archivos/65.jpg');
        const imgArch66 = require('./archivos/66.jpg');
        const imgArch67 = require('./archivos/67.jpg');
        const imgArch68 = require('./archivos/68.jpg');
        const imgArch69 = require('./archivos/69.jpg');
        const imgArch70 = require('./archivos/70.jpg');
        const imgArch71 = require('./archivos/71.jpg');
        const imgArch72 = require('./archivos/72.jpg');
        const imgArch73 = require('./archivos/73.jpg');
        const imgArch74 = require('./archivos/74.jpg');
        const imgArch75 = require('./archivos/75.jpg');
        const imgArch76 = require('./archivos/76.jpg');
        const imgArch77 = require('./archivos/77.jpg');
        const imgArch78 = require('./archivos/78.jpg');
        const imgArch79 = require('./archivos/79.jpg');
        const imgArch80 = require('./archivos/80.jpg');
        const imgArch81 = require('./archivos/81.jpg');
        const imgArch82 = require('./archivos/82.jpg');
        const imgArch83 = require('./archivos/83.jpg');
        const imgArch84 = require('./archivos/84.jpg');
        const imgArch85 = require('./archivos/85.jpg');
        const imgArch86 = require('./archivos/86.jpg');
        const imgArch87 = require('./archivos/87.jpg');
        const imgArch88 = require('./archivos/88.jpg');
        const imgArch89 = require('./archivos/89.jpg');
        const imgArch90 = require('./archivos/90.jpg');
        const imgArch91 = require('./archivos/91.jpg');
        const imgArch92 = require('./archivos/92.jpg');
        const imgArch93 = require('./archivos/93.jpg');
        const imgArch94 = require('./archivos/94.jpg');
        const imgArch95 = require('./archivos/95.jpg');
        const imgArch96 = require('./archivos/96.jpg');
        const imgArch97 = require('./archivos/97.jpg');
        const imgArch98 = require('./archivos/98.jpg');
        const imgArch99 = require('./archivos/99.jpg');
        const imgArch100 = require('./archivos/100.jpg');
        const imgArch101 = require('./archivos/101.jpg');
        const imgArch102 = require('./archivos/102.jpg');
        const imgArch103 = require('./archivos/103.jpg');
        const imgArch104 = require('./archivos/104.jpg');
        const imgArch106 = require('./archivos/106.jpg');
        const imgArch107 = require('./archivos/107.jpg');
        const imgArch108 = require('./archivos/108.jpg');
        const imgArch109 = require('./archivos/109.jpg');
        const imgArch110 = require('./archivos/110.jpg');
        const imgArch111 = require('./archivos/111.jpg');
        const imgArch112 = require('./archivos/112.jpg');
        const imgArch113 = require('./archivos/113.jpg');
        const imgArch114 = require('./archivos/114.jpg');
        const imgArch115 = require('./archivos/115.jpg');
        const imgArch116 = require('./archivos/116.jpg');
        const imgArch117 = require('./archivos/117.jpg');
        const imgArch118 = require('./archivos/118.jpg');
        const imgArch119 = require('./archivos/119.jpg');
        const imgArch120 = require('./archivos/120.jpg');
        const imgArch121 = require('./archivos/121.jpg');
        const imgArch122 = require('./archivos/122.jpg');
        const imgArch123 = require('./archivos/123.jpg');
        const imgArch124 = require('./archivos/124.jpg');
        const imgArch125 = require('./archivos/125.jpg');
        const imgArch126 = require('./archivos/126.jpg');
        const imgArch127 = require('./archivos/127.jpg');
        const imgArch128 = require('./archivos/128.jpg');
        const imgArch129 = require('./archivos/129.jpg');
        const imgArch130 = require('./archivos/130.jpg');
        const imgArch131 = require('./archivos/131.jpg');
        const imgArch132 = require('./archivos/132.jpg');
        const imgArch133 = require('./archivos/133.jpg');
        const imgArch134 = require('./archivos/134.jpg');
        const imgArch135 = require('./archivos/135.jpg');
        const imgArch136 = require('./archivos/136.jpg');
        const imgArch137 = require('./archivos/137.jpg');
        const imgArch138 = require('./archivos/138.jpg');
        const imgArch139 = require('./archivos/139.jpg');
        const imgArch140 = require('./archivos/140.jpg');
        const imgArch141 = require('./archivos/141.jpg');
        const imgArch142 = require('./archivos/142.jpg');
        const imgArch143 = require('./archivos/143.jpg');
        const imgArch144 = require('./archivos/144.jpg');
        const imgArch145 = require('./archivos/145.jpg');
        const imgArch146 = require('./archivos/146.jpg');
        const imgArch147 = require('./archivos/147.jpg');
        const imgArch148 = require('./archivos/148.jpg');
        const imgArch149 = require('./archivos/149.jpg');
        const imgArch150 = require('./archivos/150.jpg');
        const imgArch151 = require('./archivos/151.jpg');
        const imgArch152 = require('./archivos/152.jpg');
        const imgArch153 = require('./archivos/1eb508ea-b512-40a5-95ad-f730976c0ea8.jpg');
        const imgArch154 = require('./archivos/24454c88-3b81-4bed-a087-ab3b2b1609c5.jpg');
        const imgArch155 = require('./archivos/a3eaf5da-86a2-425e-b78c-839f3496a39d.jpg');
        const imgArch156 = require('./archivos/dcba3328-1704-4f17-a744-8ea5eb55ffbb.jpg');
        const imgArch157 = require('./archivos/IMG_0019.jpg');
        const imgArch158 = require('./archivos/IMG_0083.jpg');
        const imgArch159 = require('./archivos/IMG_0084.jpg');
        const imgArch160 = require('./archivos/IMG_0120.jpg');
        const imgArch161 = require('./archivos/IMG_0185.jpg');
        const imgArch162 = require('./archivos/IMG_0249.jpg');
        const imgArch163 = require('./archivos/IMG_0293.jpg');
        const imgArch164 = require('./archivos/IMG_0299.jpg');
        const imgArch165 = require('./archivos/IMG_0311.jpg');
        const imgArch166 = require('./archivos/IMG_0340.jpg');
        const imgArch167 = require('./archivos/IMG_0410.jpg');
        const imgArch168 = require('./archivos/IMG_0421.jpg');
        const imgArch169 = require('./archivos/IMG_7038.jpg');
        const imgArch170 = require('./archivos/IMG_7323.jpg');
        const imgArch171 = require('./archivos/IMG_7415.jpg');
        const imgArch172 = require('./archivos/IMG_7442.jpg');
        const imgArch173 = require('./archivos/IMG_7444.jpg');
        const imgArch174 = require('./archivos/IMG_7471.jpg');
        const imgArch175 = require('./archivos/IMG_7567.jpg');
        const imgArch176 = require('./archivos/IMG_7755.jpg');
        const imgArch177 = require('./archivos/IMG_7764.jpg');
        const imgArch178 = require('./archivos/IMG_7771.jpg');
        const imgArch179 = require('./archivos/IMG_7871.jpg');
        const imgArch180 = require('./archivos/IMG_7881.jpg');
        const imgArch181 = require('./archivos/IMG_7922.jpg');
        const imgArch182 = require('./archivos/IMG_8021.jpg');
        const imgArch183 = require('./archivos/IMG_8087.jpg');
        const imgArch184 = require('./archivos/IMG_8106.jpg');
        const imgArch185 = require('./archivos/IMG_8156.jpg');
        const imgArch186 = require('./archivos/IMG_8171.jpg');
        const imgArch187 = require('./archivos/IMG_8174.jpg');
        const imgArch188 = require('./archivos/IMG_8186.jpg');
        const imgArch189 = require('./archivos/IMG_8190.jpg');
        const imgArch190 = require('./archivos/IMG_8215.jpg');
        const imgArch191 = require('./archivos/IMG_8216.jpg');
        const imgArch192 = require('./archivos/IMG_8218.jpg');
        const imgArch193 = require('./archivos/IMG_8223.jpg');
        const imgArch194 = require('./archivos/IMG_8231.jpg');
        const imgArch195 = require('./archivos/IMG_8253.jpg');
        const imgArch196 = require('./archivos/IMG_8264.jpg');
        const imgArch197 = require('./archivos/IMG_8280.jpg');
        const imgArch198 = require('./archivos/IMG_8282.jpg');
        const imgArch199 = require('./archivos/IMG_8319.jpg');
        const imgArch200 = require('./archivos/IMG_9939.jpg');
        const imgArch201 = require('./archivos/IMG_9956.jpg');

        if (document.getElementById('columna2')) {
            document.getElementById('columna2').classList.remove('d-none');
        }

        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var text1 = "Sus nombres no importaron intercambiaron pies por manos y relatos por abrazos";
            var text2 = "Pidió que la mire a los ojos pero no pudo a los dos juntos entonces miró el izquierdo y sintió que era poco";
            var text3 = "El órgano de mar tenía corazón era de pelón cambiaba su canción con cada ola de sa";
            var text4 = "Lo que nos pasa en un avión";
            var text5 = "Posible caricia";
            var text6 = "El momento en que dos personas se enamoran";
            var text7 = "Con el brochecito del silencio en las pestañas";
            var text8 = "De mi brazo salió lo que sabía entonces tuve que, darme la mano";
            var text9 = "Cayendo porque quiero convicción variable";
            var text10 = "Precipicio blandito";
            var text11 = "Apurate de a poco";
            var text12 = "Enfrente tu amiga que te ofrece galletitas";
            var text13 = "Nadie pregunta si hay curitas hoy todo se arregla con caritas";
            var text14 = "Nico del pasado";
            var text15 = "No sabíamos nada de lo que";
            var text16 = "Te cuento un secreto con la condición de jamás guardarlo";
            var text17 = "Todos los días mis ojos ven una peli distinta abro y cierro la cortina";
            var text18 = "Todavía no digiere el 2019";
            var text19 = "Mayoría de edad minoría en todo lo demás";
            var text20 = "Jacinto baja la ventanilla del auto para que el viento le de en la cara";
            var text21 = "Alguna vez de nuevo";
            var text22 = "Prolongación del sonido del timbre";
            var text23 = "Vivacidad entusiasmo y optimismo";
            var text24 = "Lo que nunca te voy a contar";
            var text25 = "No te preocupes, yo te cuido";
            var text26 = "Me robaron la dicotomía";
            var text27 = "Empresa del reencuentro";
            var text28 = "Pero, y la caricia?";
            var text29 = "Las partes encerradas";
            var text30 = "No saber nada te hizo bien";
            var text31 = "Cuando nos volvamos a encontrar";
            var text32 = "Las colinas se mojan y prueban bostezos";
            var text33 = "Arrebato húmedo";
            var text34 = "Entre el café y la esquina con calles de nombres difíciles";
            var text35 = "Animal confundido amigo suelto";
            var text36 = "Me queda grande tu expectativa y chica la ropa";
            var text37 = "El viaje a mar del plata, estuvo re lindo";
            var text38 = "Casilla de correo portaminas tres historias";
            var text39 = "Desde que las cosas están así de bien, no se me va el pánico";
            var text40 = "Vomitaste todo no limpiaste nada";
            var text41 = "Amor es solo monstruo, como cuando te presté las zapatillas";
            var text42 = "Ya salimos a la ruta, empezó este viaje en auto";
            var text43 = "El cumpleaños del fogón";
            var text44 = "No pesqué nada tengo amigos que no conozco";
            var text45 = "Pelean por comida no saben que mastican su propia lengua";
            var text46 = "Los mosquitos duermen y tu madre no quiere";
            var text47 = "Pidiendo plata en la escalera caracol";
            var text48 = "Atrevimiento y escándalo";
            var text49 = "Derrotas que caminan";
            var text50 = "Yendo al teatro con los dragones";
            var text51 = "Me comí una flor me dolió un montón";
            var text52 = "Te caíste tantas veces no parás de aprender";
            var text53 = "Se ratio del colegio";
            var text54 = "Me entró una envidia en el ojo izquierdo";
            var text55 = "Caminaron tanto por la orilla que ahora eran sombrilla y mar";
            var text56 = "Todo era mucho";
            var text57 = "El helado hace bien";
            var text58 = "Masticando una casita feliz";
            var text59 = "La semilla tenia mucho jugo";
            var text60 = "Tanto que pensar ahora que me dijeron perdiste el celu";
            var text61 = "Música local";
            var text62 = "La vuelta a la plata, un cortecito";
            var text63 = "Animal pegando el salto ";
            var text64 = "Un zapato ajustado cagado en las patas";
            var text65 = "Te dejó por otro";
            var text66 = "A mí nadie me enseño protocolo";
            var text67 = "Todos los colores del bidón";
            var text68 = "Cambiando figus en el tren";
            var text69 = "Nadie me preguntó nadie me miró";
            var text70 = "El sol entre las montañas viejas";
            var text71 = "Chica de pelo largo";
            var text72 = "Modos de andar";
            var text73 = "Criatura de río";
            var text74 = "Se hizo grande el tiempo";
            var text75 = "Se fueron de viaje no te avisaron";
          
            
        } else {
            var text1 = "Their names didn´t matter they exchanged feet for hands and tales for hugs";
            var text2 = "She asked him to look her in the eyes but he couldn´t both at once so he looked at the left one and felt it was insufficient";
            var text3 = "The sea organ had a nectarine heart it changed its song with each wave of salt";
            var text4 = "That which happens to us on a plane";
            var text5 = "Possible caress";
            var text6 = "The moment two people fall in love";
            var text7 = "With the tiny  pin of silence on the lashes";
            var text8 = "Out of my arm came what I knew so I had to, hold my hand";
            var text9 = "Falling because I want changeable conviction";
            var text10 = "Soft precipice";
            var text11 = "Hurry up slowly";
            var text12 = "Across your friend who offers you cookies";
            var text13 = "Nobody asks if there are plasters today everything gets fixed with plasters";
            var text14 = "Nico from the past";
            var text15 = "We didn´t know anything about what";
            var text16 = "I´ll tell you a secret on condition you never keep it";
            var text17 = "Every day my eyes watch a different movie I open and close the curtain";
            var text18 = "Still not digesting 2019";
            var text19 = "Age of majority minority for everything else";
            var text20 = "Jacinto rolls down the car window to feel the wind in his face";
            var text21 = "At some point again";
            var text22 = "Prolonged doorbell sound";
            var text23 = "Vivacity, enthusiasm and optimism";
            var text24 = "What I´m never going to tell you";
            var text25 = "Don´t worry, I´ll take care of you";
            var text26 = "They stole my dichotomy";
            var text27 = "Attempting to reunite";
            var text28 = "But, and the caress?";
            var text29 = "Locked up parts";
            var text30 = "Knowing nothing did you good";
            var text31 = "When we meet again";
            var text32 = "The hills get wet and try out yawns";
            var text33 = "Wet outburst";
            var text34 = "Between the café and the corner with difficult street names";
            var text35 = "Confused animal friend on the loose";
            var text36 = "Your expectations are too great for me and the clothes  too small";
            var text37 = "The trip to mar del plata, it was awesome";
            var text38 = "Mailbox lead holder three stories";
            var text39 = "Now that things are going so well, my panic won´t go away";
            var text40 = "You threw up everything  you didn´t clean up";
            var text41 = "Love is only monster, like when I lent you the sneakers";
            var text42 = "We´re on the road, this car journey has begun";
            var text43 = "Bonfire birthday";
            var text44 = "I didn´t catch anything I´ve got friends I don´t know";
            var text45 = "They fight for food they don´t know they are chewing their own tongues";
            var text46 = "The mosquitos sleep and your mother doesn´t want to";
            var text47 = "Asking for money on the spiral staircase";
            var text48 = "Audacity and scandal";
            var text49 = "Walking defeats";
            var text50 = "Going to the theatre with the dragons";
            var text51 = "I ate a flower it hurt a lot";
            var text52 = "You fell so many times you don't stop learning ";
            var text53 = "Ran away from school  ";
            var text54 = "I got envy in my left eye   ";
            var text55 = "They walked so much along the shore that now they were umbrella and sea   ";
            var text56 = "Everything was too much ";
            var text57 = "Ice Cream is good";
            var text58 = "Chewing on a happy house";
            var text59 = "The seed had a lot of juice";
            var text60 = "So much to think about now that they told me you lost your cell phone ";
            var text61 = "Local Music";
            var text62 = "The return to la plata, a little cut";
            var text63 = "Animal hitting the jump ";
            var text64 = "A tight shoe shitted on the legs ";
            var text65 = "He left you for another";
            var text66 = "Nobody taught me the protocol";
            var text67 = "All colors of the drum ";
            var text68 = "Exchanging cards on the train";
            var text69 = "Nobody asked me, nobody looked at me";
            var text70 = "The sun between the old mountains";
            var text71 = "Long hair girl";
            var text72 = "Walking ways";
            var text73 = "Lake creature ";
            var text74 = "It turns big the time";
            var text75 = "They went on a trip they didn't warn you";
        }
        return (
                <div className="complete archivo">
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch1.default} className='w-100 imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch2.default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text3}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch3.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                    <img src={imgArchA.default} className='w-100   imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <div>
                                        <label className='textos_archivo'>{text2}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                    </div>                            
                                </div>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text1}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                    <img src={imgArchB.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArchC.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md-3">
                            <img src={imgArch4.default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            <img src={imgArch8.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <div>
                                <label className='textos_archivo'>{text5}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                            </div>
                            <img src={imgArch9.default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <div className="col-6">
                                        <label className='textos_archivo'>{text4}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                    </div>
                                </div>
                            </Row>
                            <img src={imgArch10.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>     
                        </div>
                        <div className="col-6 col-md-3">
                            <img src={imgArch11.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <div>
                                <label className='textos_archivo'>{text6}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                            </div>
                            <div>
                                <label className='textos_archivo'>{text7}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text8}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                    </div>
                                    <img src={imgArch13.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch12.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text9}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2016</label>
                                    </div>
                                    <img src={imgArch14.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12" >
                                    <img src={imgArch15.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6" >
                                    <img src={imgArch16.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <div>
                                        <label className='textos_archivo'>{text11}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                </div>
                                <div className="col-6" >
                                    <div>
                                        <label className='textos_archivo'>{text10}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                    </div>
                                    <img src={imgArch17.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12" >
                                    <img src={imgArch18.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6" >
                                    <img src={imgArch19.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch20.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text17}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                </div>
                                <div className="col-6" >
                                    <div>
                                        <label className='textos_archivo'>{text15}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                    <img src={imgArch21.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <div>
                                        <label className='textos_archivo'>{text16}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <img src={imgArch23.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch24.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <img src={imgArch25.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch26.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text12}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                    <img src={imgArch28.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <div>
                                        <label className='textos_archivo'>{text14}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2017</label>
                                    </div>
                                    <img src={imgArch29.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch27.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <div>
                                        <label className='textos_archivo'>{text13}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2021</label>
                                    </div>
                                    <img src={imgArch30.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch31.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch32.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                        </div>                
                    </Row>
                    <Row  >
                        <div className="col-6">
                            <img src={imgArch22.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text18}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                    </div>
                                    <div>
                                        <label className='textos_archivo'>{text19}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                    </div>              
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md-3">
                            <img src={imgArch33.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            <div>
                                <label className='textos_archivo'>{text20}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2017</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch34.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2018</label> 
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <img src={imgArch35.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>     
                        </div>
                        <div className="col-6 col-md-3">
                            <img src={imgArch36.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>  
                        </div>
                    </Row>
                    <Row  >
                        <div className="col-12 col-md-6">
                            <Row  >
                                <div className="col-6">
                                    <img src={imgArch37.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch38.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text21}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                    <img src={imgArch39.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>                
                                </div>                
                            </Row>
                            <Row  >
                                <div className="col-12">
                                    <img src={imgArch40.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row  >
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text24}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2015</label>
                                    </div>
                                    <img src={imgArch41.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch42.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">                           
                                    <img src={imgArch43.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>                
                                </div>
                            </Row>
                            <Row  >
                                <div className="col-12">
                                    <img src={imgArch44.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch47.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-6">
                                    <div>
                                        <label className='textos_archivo'>{text22}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2021</label>
                                    </div>
                                    <img src={imgArch48.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-md-6">
                                    <img src={imgArch49.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text23}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                    </div>
                                    <img src={imgArch50.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch51.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text25}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                    <div>
                                        <label className='textos_archivo'>{text26}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch52.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch53.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text28}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2021</label>
                
                                    </div>                
                                </div>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text27}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                    </div>
                                    <img src={imgArch54.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text29}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                    </div>
                                </div>
                            </Row>                
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md-3">
                            <img src={imgArch45.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            <div>
                                <label className='textos_archivo'>{text30}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch56.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            <img src={imgArch57.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            <div>
                                <label className='textos_archivo'>{text33}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch58.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                        </div>
                
                        <Col xs={12} lg={9} >
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                            <img src={imgArch46.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2018</label> 
                            </Col>
                            <Col xs={12} md={6} lg={8}>
                            <br/><br/>
                            <img src={imgArch55.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2018</label> 
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={8}>
                            <Row>
                                <Col>
                                <img src={imgArch59.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2018</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text31}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                </div>
                                <img src={imgArch60.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch61.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                                <Col>
                                <img src={imgArch62.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch63.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch64.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2018</label>   
                                </Col>
                            </Row>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                            <img src={imgArch65.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            <div>
                                <label className='textos_archivo'>{text32}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                            </div>
                            <div>
                                <label className='textos_archivo'>{text34}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                            </div>
                            <img src={imgArch66.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            <img src={imgArch67.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                        </Row>   
                        </Col>
                    </Row>
                    <Row >
                        <div className="col-12 col-md-6" >
                            <Row>
                                <Col>
                                <img src={imgArch68.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col> </Col>
                                <Col>
                                <img src={imgArch69.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch70.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch71.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch72.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch73.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch74.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text36}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                </div>
                                <img src={imgArch75.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <div>
                                    <label className='textos_archivo'>{text37}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2014</label>
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch84.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text39}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                </div>
                                </Col>
                                <Col>
                                <img src={imgArch85.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <Col></Col>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text35}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                </div>
                                <img src={imgArch76.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch77.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch78.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch79.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <div>
                                    <label className='textos_archivo'>{text38}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                </div>
                                </Col>
                                <Col>
                                <img src={imgArch80.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch81.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                <img src={imgArch82.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <Col xs={12} lg={9}>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                            <img src={imgArch86.default} className='w-100   imgarc d-block ml-auto margin_sup_10' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                            <Col xs={12} md={6} lg={8}> 
                            <img src={imgArch87.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={8}> 
                            <img src={imgArch88.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                            </Col>
                        </Row>
                        </Col>
                        <Col>
                        <img src={imgArch83.default} className='w-100   imgarc d-block ml-auto margin_sup_60' alt=""/>
                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        <div>
                            <label className='textos_archivo'>{text40}
                                <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                        </div>
                        <div>
                            <label className='textos_archivo'>{text41}
                                <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                        </div>
                        <img src={imgArch96.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        <img src={imgArch97.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </Col>
                    </Row>
                    <Row >
                        <div className="col-12 col-md-6 ">
                            <Row>
                                <Col >
                                <img src={imgArch89.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </Col>
                                <Col className="margin_sup_20">
                                <div>
                                    <label className='textos_archivo'>{text40}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                </div>
                                <img src={imgArch90.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <img src={imgArch91.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch92.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch93.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch95.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </Col>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text43}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                </div>
                                <img src={imgArch94.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <Row>
                                <Col>
                                <img src={imgArch98.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text42}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2021</label>
                                </div>
                                </Col>
                                <Col>
                                <img src={imgArch99.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch100.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text44}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2017</label>
                                </div>
                                <img src={imgArch101.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                                <Col>
                                <img src={imgArch102.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </Col>
                            </Row>
                        </div>                
                    </Row>
                    <Row  >
                        <Col xs={12} lg={9}>
                        <Row>
                            <Col xs={12} md={6} lg={4}></Col>
                            <Col xs={12} md={6} lg={8}>
                            <img src={imgArch103.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={8}>
                            <img src={imgArch106.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                            <div>
                                <label className='textos_archivo'>{text45}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch110.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                        </Row>                
                        </Col>
                        <Col>
                        <img src={imgArch104.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                        <img src={imgArch109.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </Col>
                    </Row>
                    <Row  >
                        <div className="col-12 col-md-6">
                            <Row>
                                <Col>
                                <img src={imgArch107.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>  
                                </Col>
                                <Col></Col>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <Col className='margin_sup_35'>
                                <img src={imgArch111.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row  >
                        <div className="col-12 col-md-6">
                            <div>
                                <label className='textos_archivo'>{text46}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                            </div>
                            <img src={imgArch108.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <Col></Col>
                                <Col>
                                <img src={imgArch112.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch114.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch113.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <div>
                                <label className='textos_archivo'>{text47}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                            </div>
                            <img src={imgArch115.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                
                        </div>             
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row  >
                                <Col className="margin_sup_20">
                                <img src={imgArch116.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row  >
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text49}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                </div>
                
                                <img src={imgArch117.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>  
                                </Col>
                                <Col>
                                <img src={imgArch118.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <Col className="margin_sup_20">
                                <img src={imgArch119.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                <div>
                                    <label className='textos_archivo'>{text48}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                </div>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <img src={imgArch123.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>   
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col>
                                <img src={imgArch123.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <Col xs={12} lg={9}>
                        <Row  >
                            <Col xs={12} md={6} lg={4}>
                            <img src={imgArch120.default} className='w-100   imgarc d-block ml-auto margin_sup_35' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            <div>
                                <label className='textos_archivo'>{text50}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                            </div>
                            <img src={imgArch121.default} className='w-100   imgarc d-block ml-auto margin_sup_35' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </Col>
                            <Col xs={12} md={6} lg={8}> 
                            <Row>
                                <Col>
                                <img src={imgArch122.default} className='w-100   imgarc d-block ml-auto margin_sup_20' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                <div>
                                    <label className='textos_archivo'>{text51}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                </div>
                                </Col>
                            </Row>                
                            </Col>
                        </Row>
                
                        </Col>
                        <Col>
                        <img src={imgArch124.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6" >
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch125.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text52}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2017</label>
                
                                    <img src={imgArch126.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch127.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                
                                    <img src={imgArch128.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text53}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <img src={imgArch129.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <img src={imgArch130.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch131.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text54}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[159].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[160].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <img src={imgArch[162].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[164].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[165].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text56}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[169].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>    
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[168].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                
                                    <img src={imgArch148.default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[173].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text57}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>  
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[175].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                
                                    <img src={imgArch[177].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[180].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[182].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                
                                    <img src={imgArch[183].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[153].default} className='w-100   imgarc d-block ml-auto ' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>                          
                        </div>
                        <div className="col-12 col-md-6" >
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[156].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch133.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <img src={imgArch132.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch134.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch135.default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[157].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[158].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[161].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <img src={imgArch[163].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text55}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[166].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[167].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[170].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[171].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <label className='textos_archivo'>{text55}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                    <img src={imgArch[174].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[172].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[176].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                    <label className='textos_archivo'>{text58}
                                        <span className='texto_decor' ><br/>{">>>>"}</span> 2016</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[178].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch[179].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[182].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-6">
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[184].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch[185].default} className='w-100   imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <div className="col-12">
                                <Row>
                                    <div className="col-6">
                                        <div>
                                            <label className='textos_archivo'>{text59}
                                                <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                                        </div>
                                        <img src={imgArch[206].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>
                                    <div className="col-6">
                                        <img src={imgArch[207].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    </div>
                                </Row>
                                <div className="col-6">
                                    <div>
                                        <label className='textos_archivo'>{text60}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2021</label>
                                    </div>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <img src={imgArch[212].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <Row>
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <img src={imgArch[215].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>                          
                                        <img src={imgArch[217].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>
                                </Row>
                                <img src={imgArch[219].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <div className="col-6">
                                    <img src={imgArch[221].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-12">
                                <img src={imgArch[208].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                            </div>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[209].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch[211].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div >
                                        <label className='textos_archivo'>{text62}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2017</label>
                                        <img src={imgArch[214].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[210].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <div>
                                        <label className='textos_archivo'>{text61}
                                            <span className='texto_decor' ><br/>{">>>>"}</span> 2016</label>                                
                                    </div>
                                    <img src={imgArch[213].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    <img src={imgArch[216].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                </div>
                                <div className="col-12">
                                    <img src={imgArch[218].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[220].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div>
                                    <img src={imgArch[222].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <div className="col-md-3 col-6">                                
                                <label className='textos_archivo mt-auto '>{text63}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2021</label>
                                <img src={imgArch[223].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch[227].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                <img src={imgArch[228].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                            </div>
                            <div className="col-12 col-md-6">
                                <Row>
                                    <div className="col-12">
                                        <img src={imgArch[224].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <img src={imgArch[229].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        <img src={imgArch[231].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>
                                </Row>
                            </div>
                            <div className="col-md-3 col-6">
                                <img src={imgArch[225].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch[226].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <label className='textos_archivo_p'>{text64}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2015</label>
                                <img src={imgArch[230].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch[232].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <Row>
                                <div className="col-12 col-md-6">
                                    <div className="col-12 margin_sup_40">
                                        <img src={imgArch[234].default} className='w-100 imgarc margin_sup_90 ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                    </div>                                    
                                    <Row>
                                        <div className="col-6 ">
                                            <label className='textos_archivo margin_sup_tx'>{text65}
                                                <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                            <img src={imgArch[237].default} className='w-100 imgarc  ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                        <div className="col-6">
                                            <img src={imgArch[236].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                            <label className='textos_archivo'>{text66}
                                                <span className='texto_decor' ><br/>{">>>>"}</span> 2017</label>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-6">
                                            <img src={imgArch[239].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                        </div>
                                        <div className="col-6">
                                            <img src={imgArch[240].default} className='w-100 imgarc margin_sup_10 ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <img src={imgArch[241].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>                          
                                            <img src={imgArch[245].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                    </Row>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="col-6 margin_sup_25">
                                        <img src={imgArch[233].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        <img src={imgArch[235].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>
                                    <img src={imgArch[238].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <Row>
                                        <div className="col-6">
                                            <img src={imgArch[242].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                        <div className="col-6">
                                            <img src={imgArch[243].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <img src={imgArch[244].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>                          
                                            <label className='textos_archivo'>{text67}
                                                <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                        </Row>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <div className="col-12">
                                <img src={imgArch[246].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[249].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>                    
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[251].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>                    
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[253].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[254].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <div className="row textos_archivo">
                                <label className='textos_archivo'>{text68}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                            </div>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[257].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' > {">>>>"} </span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[258].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[247].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[248].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[250].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-12">
                                    <img src={imgArch[252].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[255].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[256].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>  
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[259].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>  
                            </Row>
                        </div>            
                    </Row>
                    <Row>
                        <div className="col-md-3 col-6">
                            <div className="textos_archivo">
                                <label className='textos_archivo'>Cambiando figus en el tren
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2018</label>
                            </div>
                            <div className="col-12">
                                <img src={imgArch[261].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>  
                            <div className="col-12">
                                <img src={imgArch[264].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div> 
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="col-8">
                                <img src={imgArch[260].default} className='w-100 ml-auto margin-top-260 imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <div className="col-4"></div>
                            <Row>
                                <div className="col-4">
                                    <Row>
                                        <img src={imgArch[262].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                    <div className="row textos_archivo">
                                        <label> {text69} <br/><span className='texto_decor' >{">>>>"}</span> 2018</label>
                                    </div>
                                    <Row>
                                        <img src={imgArch[265].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                    <Row>
                                        <img src={imgArch[267].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>                                      
                                <div className="col-8">
                                    <Row>
                                        <img src={imgArch[263].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                    <Row>
                                        <div className="col-6"></div>
                                        <div className="col-6">
                                            <img src={imgArch[266].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                    </Row>
                                </div>  
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[269].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[272].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <Row>
                                        <label className="textos_archivo"> {text71} <br /><span className='texto_decor' >{">>>>"}</span> 2019</label>
                                    </Row>
                                    <Row>
                                        <img src={imgArch[274].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                    <Row>
                                        <img src={imgArch[275].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[277].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <Row>
                                        <img src="archivos/143.jpg" className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                    <Row>
                                        <img src={imgArch[282].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>
                                <div className="col-6"></div>
                                <Row>
                                    <div className="col-12">
                                        <img src={imgArch[284].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>
                                </Row>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-12">
                                <img src={imgArch[268].default} className='w-100 margin-top-268 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <Row>
                                <div className="col-6">
                                    <label className="textos_archivo"> {text70} <br />
                                        <span className='texto_decor' >{">>>>"}</span> 2017</label>
                
                                    <img src={imgArch[271].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[273].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[270].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[276].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>                          
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[278].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[280].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <label className="textos_archivo"> {text73} <br />
                                        <span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[279].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <label className="textos_archivo"> {text72} <br />
                                        <span className='texto_decor' >{">>>>"}</span> 2018</label>
                
                                    <img src={imgArch[281].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[283].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[285].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[286].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <div className="col-12">
                                <Row>
                                    <div className="col-6">
                                        <img src={imgArch[287].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        <img src={imgArch[288].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </div>  
                                    <img src={imgArch[291].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <Row>
                                        <div className="col-6">
                                            <label className='textos_archivo'>Nomonstruo
                                                <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                            <img src={imgArch[295].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                            <img src={imgArch[297].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                            <img src={imgArch[298].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                        </div>
                                        <div className="col-6">
                                            <img src={imgArch[294].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                        </div>
                                    </Row>
                                </Row>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-6">
                                <label className='textos_archivo margin_sup_10'>{text74}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2020</label>
                                <img src={imgArch[289].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            </div>
                            <img src={imgArch[290].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[292].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[293].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[296].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[300].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[299].default} className='w-100 imgarc margin_sup_25 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <div className="col-6">
                                <img src={imgArch[302].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                <img src={imgArch[304].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div>
                                <label className='textos_archivo margin_sup_30 '>{text75}
                                    <span className='texto_decor' ><br/>{">>>>"}</span> 2019</label>
                            </div>
                            <img src={imgArch[301].default} className='w-100  imgarc margin_sup_20 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            <img src={imgArch[303].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[306].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[308].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                    <img src={imgArch[311].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[309].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                </div>
                            </Row>
                            <img src={imgArch[313].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[316].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[318].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[319].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[321].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[324].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[322].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[305].default} className='w-100  imgarc margin_sup_23 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[307].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                    <img src={imgArch[310].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                                </div>
                            </Row>
                            <img src={imgArch[312].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label> 
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[314].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[315].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[317].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[320].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src="archivos/139.jpg" className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[323].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[325].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[326].default} className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3"></div>
                        <div className="col-12 col-md-9">
                            <Row>
                                <div className="col-8">
                                    <img src={imgArch[327].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-4">
                                    <Row>
                                        <img src={imgArch[328].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                    <Row>
                                        <img src={imgArch[329].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[330].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[333].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[335].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[337].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <Row>
                                        <img src={imgArch[338].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[340].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[342].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <Row>
                                        <img src={imgArch[343].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[345].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[347].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[350].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[353].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[349].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[351].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[331].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[332].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[334].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[336].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[339].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[341].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[344].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[346].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[348].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[352].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3"></div>
                        <div className="col-md-9 col-12">
                            <Row>
                                <div className="col-8">
                                    <img src={imgArch[354].default} className='w-100 margin-top-354 ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-4">
                                    <img src={imgArch[355].default} className='w-100 margin-top-355 ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <Row>
                                        <img src={imgArch[356].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                        <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    </Row>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-8">
                                    <Row>
                                        <div className="col-6">
                                            <img src={imgArch[357].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                        <div className="col-6">
                                            <img src={imgArch[358].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                        </div>
                                    </Row>
                                </div>
                                <div className="col-4"></div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[359].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[361].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[362].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[365].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[367].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[369].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[372].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[375].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <img src={imgArch[360].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[363].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[364].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[366].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[368].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[370].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[371].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[373].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[374].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[376].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <img src={imgArch[379].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <img src={imgArch[381].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <img src={imgArch[383].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[377].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[382].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[384].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[378].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <img src={imgArch[380].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[385].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <div className="col-6">
                                <img src={imgArch[388].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch[389].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch[392].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-6">
                                <img src={imgArch[386].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch[387].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[390].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[391].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3"></div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[393].default} className='w-100 imgarc margin_sup_50 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[394].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020 </label>
                                    <img src={imgArch[396].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[400].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[401].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[404].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[395].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[397].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020 </label>
                                    <img src={imgArch[398].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[399].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[402].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020 </label>
                                    <img src={imgArch[405].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[403].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020 </label>
                                    <img src={imgArch[406].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md-3">
                            <img src={imgArch[407].default} className='w-100 imgarc margin_sup_10 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020 </label>
                            <img src={imgArch[408].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[409].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[410].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[412].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src="archivos/87.jpg" className='w-100  imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[418].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[414].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-6">
                                <img src={imgArch[411].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch[413].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[415].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[417].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[416].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[419].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>               
                    </Row>
                    <Row>
                        <div className="col-md-3"></div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[421].default} className='w-100 imgarc margin_sup_28 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-6 col-md-3">
                            <img src={imgArch[420].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[422].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[424].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[427].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[430].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[432].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[433].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-6">
                                <img src={imgArch[423].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src={imgArch[425].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch[426].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[428].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[429].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <img src={imgArch[431].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[434].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src="archivos/150.jpg" className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[435].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[436].default} className='w-100 imgarc margin_sup_39 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <img src={imgArch[439].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[437].default} className='w-100 imgarc margin_sup_13 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[438].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <div className="col-6">
                                <img src={imgArch[440].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch[443].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <div className="col-6">
                                <img src={imgArch[448].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                <img src="archivos/146.jpg" className='w-100 imgarc d-block ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-6">
                                <img src={imgArch[441].default} className='w-100 imgarc margin_sup_7 ml-auto' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                            <img src={imgArch[442].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[444].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[446].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[449].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[445].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[447].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[450].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md-3">
                            <img src={imgArch[452].default} className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <img src="archivos/140.jpg" className='w-100 imgarc d-block ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[451].default} className='w-100 imgarc margin_sup_10 ml-auto' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[453].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[495].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[458].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[454].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[455].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[456].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[457].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[459].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6 col-md-3">
                            <img src={imgArch[460].default} className='w-100 margin-top-460 ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                            <img src={imgArch[462].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                            <img src={imgArch[464].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-9">
                            <Row>
                                <div className="col-8">
                                    <img src={imgArch[463].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-4">
                                    <img src={imgArch[461].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src="archivos/154.jpg" className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <img src={imgArch[465].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src="archivos/144.jpg" className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[467].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[470].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[471].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>    
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-6">
                                    <img src="archivos/141.jpg" className='w-100 margin-top-141 ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[466].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[468].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6"></div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[469].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[390].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[472].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3 col-6">
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[473].default} className='w-100 margin-top-473 ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[475].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[474].default} className='w-100 margin-top-474 ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-6 col-md-3"></div>               
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[477].default} className='w-100 margin-top-476 ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[479].default} className='w-100 margin-top- ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[481].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[479].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[476].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch9.default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[478].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[480].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[482].default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[483].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                            <div className="col-6">
                                <img src={imgArch145.default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <img src={imgArch[484].default} className='w-100 margin-top-484 ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                            <img src={imgArch[485].default} className='w-100 d-block imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[486].default} className='w-100 margin-top-486 ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6">
                                    <img src={imgArch[488].default} className='w-100 d-block imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[491].default} className='w-100 d-block imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                
                                    <img src={imgArch[493].default} className='w-100 d-block imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                                <div className="col-6">
                                    <img src={imgArch[489].default} className='w-100 d-block imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src={imgArch[487].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                            <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                            <Row>
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <img src={imgArch[490].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                    <img src={imgArch[492].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-12">
                                    <img src={imgArch[494].default} className='w-100 d-block ml-auto  imgarc' alt=""/>
                                    <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2020</label>
                                </div>
                            </Row>
                        </div>

                        {/* Nuevas */}

                        <div className="col-12 col-md-12">
                        <Row>
                            <div className="col-md-3 col-6">
                                <img src={imgArch153.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch154.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch155.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch156.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>


                            <div className="col-md-3 col-6">
                                <img src={imgArch157.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch158.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch159.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch160.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch161.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch162.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch163.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch164.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>


                            <div className="col-md-3 col-6">
                                <img src={imgArch165.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch166.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch167.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch168.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch169.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch170.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch171.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>


                            <div className="col-md-3 col-6">
                                <img src={imgArch172.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch173.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch174.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch175.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch176.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch177.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch178.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch179.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch180.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>


                            <div className="col-md-3 col-6">
                                <img src={imgArch181.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch182.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch183.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch184.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch185.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch186.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch187.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch188.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch189.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch190.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch191.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch192.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch193.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch194.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch195.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch196.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch197.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch198.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch199.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch200.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>

                            <div className="col-md-3 col-6">
                                <img src={imgArch201.default} className='w-100 margin-top-482 ml-auto  imgarc' alt=""/>
                                <label className='texto1'><span className='texto_decor' >{">>>>"}</span> 2021</label>
                            </div>
                            </Row>
                        </div>
                    </Row>
                </div>
                );
    }
}

export default Archivos;
import React, { Component, createRef } from 'react'
import EstructuraImg from '../EstructuraImg/estructuraImg';

class Grabados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lsGrab: [],

        }
        this.myRef = createRef();

    }

    async componentDidMount() {
        if (localStorage.getItem('Lenguaje') == 'espanish') {
            var language = "https://mimi.zimbadigital.net/backend/getGrabados.php";
        } else {
            var language = "https://mimi.zimbadigital.net/backend/getGrabados.php?lang=en";
        }
        let url = language;
        fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    this.setState({lsGrab: res});
                });
    }

    render() {
        return (<main ref={this.myRef} className=' m-0 p-0 curRojo Escultura m10top' >
    { this.state.lsGrab && this.state.lsGrab.map((item, i) => {
                        var lsSubImg = item.galeria[0].SubGaleria;
                            var lsHastag = item.Hash;
                            var limite = null;
                            var estilosTex = null;
                            var ocu = false;
                            var brAct;
                            var widthSub;
                            var puntoFinal = null;
                            var SubLim = null;


                            if (i == 1) {
                                estilosTex = 'lbSty';

                            }
                            if (i == 2) {
                                estilosTex = 'lbSty';

                            }
                            if (i == 3) {
                                estilosTex = 'lbSty';
                                brAct = true;

                            }

                            if (i == 12) {
                                estilosTex = 'labelStlye';
                                brAct = true;

                            }


                            if (i == 14) {
                                ocu = true;
                                limite = 3;
                                SubLim = true;
                                widthSub = 'subImg';
                                puntoFinal = true;


                            }


                            return (
                                    <EstructuraImg
                                        refe={this.myRef}
                                        Titulo={item.Titulo}
                                        Rojo={item.Descripcion}
                                        Rojo2={item.Medida}
                                        Verde={"conflicto "}
                                        Azul={"fantasia"}
                                        widthSub={widthSub}
                                        Lim = {limite}
                                        brAct={brAct}
                                        SubLim={SubLim}
                                        EstilosTex={estilosTex}
                                        showMore={ocu}
                                        hideFotos={true}
                                        WidthImg={item.galeria[0].WidthImg}
                                        ImgPrincipal={item.galeria[0].ImgPrincipal}
                                        listGale={lsSubImg}
                                        hastLs={lsHastag}
                                        puntoFinal={puntoFinal}
                                        elementOrigin={'esculturas'}
                                        />
                                );
                            })}
</main>);
    }
}

export default Grabados;